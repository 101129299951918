<template>
    <div>
        <slot>
            <button type="button" class="pb-1 pt-3 pb-r bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight outline-none w-full" @click="$refs.fileInput.click()">
                Upload
            </button>
        </slot>
        <input type="file" ref="fileInput" class="hidden" :multiple="multiple" :accept="allowedTypes" @change="handleFileChange($event)">
        <modal :showing="isUploading" transition="scale" :closeBtn="false" class="text-center">
            <div class="text-white text-4xl uppercase font-bold tracking-wider">Uploading Files.<br>Please wait...</div>
        </modal>
        <modal :showing="isError" transition="scale" @close="isError = false" class="text-center">
            <div class="text-red-500 text-4xl uppercase font-bold tracking-wider">Error Uploading File(s).<br>Please try again.</div>
            <button type="button" class="bg-white text-black font-dense tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight pt-2 pb-1 outline-none focus:bg-turquoise w-1/3 mx-auto uppercase mt-2" @click="isError = false">Ok</button>
        </modal>
    </div>
</template>

<script>
    import Modal from '@/components/Modal.vue';

    export default {
        name: 'FileSelectUpload',
        data: function() {
            return {
                files: [],
                isUploading: false,
                isError: false
            }
        },
        props: {
            multiple: {
                type: Boolean,
                default: false
            },
            allowedTypes: {
                type: [String, Boolean],
                default: false
            },
            validate: {
                type: [Function, Boolean],
                default: false
            },
            isValid: {
                type: Boolean,
                default: true
            }
        },
        components: {
            Modal
        },
        mounted: function() {
            if( this.$slots.default ) {
                const $self = this;
                this.$slots.default[0].elm.onclick = function () {
                    $self.$refs.fileInput.click();
                };
            }
        },
        methods: {
            handleFileChange: function(event) {
                const $self = this;
                this.$emit('input', event.target.files);
                if( this.multiple ) {
                    for (let i = 0; i < event.target.files.length; i++) {

                        if( this.validate ) {
                            this.validate(event.target.files[i]);
                            (function(index) {
                                setTimeout(function() {
                                    if( $self.isValid ) {
                                        $self.files.push(event.target.files[index]);
                                    } else {
                                        event.target.value = '';
                                    }
                                }, 100)
                            })(i);
                        } else {
                            this.files.push(event.target.files[i]);
                        }

                    }
                } else {

                    if( this.validate ) {
                        this.validate(event.target.files[0]);
                        setTimeout(function() {
                            if( $self.isValid ) {
                                $self.files[0] = event.target.files[0];
                            } else {
                                event.target.value = '';
                            }
                        }, 100);

                    } else {
                        this.files[0] = event.target.files[0];
                    }

                }
            },
            saveFiles: function() {
                return new Promise(resolve => {
                    if( this.files.length ) {
                        this.isUploading = true;
                        const $self = this, keys = [],
                            uploadFiles = function () {
                                const formData = new FormData(), file = $self.files.shift();

                                formData.append('media', file);

                                $self.$http({
                                    url: 'upload-media',
                                    method: 'POST',
                                    data: formData
                                })
                                    .then(function (response) {
                                        keys.push(response.data.key);
                                        if ($self.files.length === 0) {
                                            $self.isUploading = false;
                                            if ($self.multiple)
                                                resolve(keys);
                                            else
                                                resolve(keys.pop());
                                        } else {
                                            uploadFiles();
                                        }
                                    })
                                    .catch(function () {
                                        $self.isUploading = false;
                                        $self.isError = true;
                                        resolve(false);
                                    });
                            };

                        uploadFiles();

                    } else {
                        this.multiple ? resolve([]) : resolve('');
                    }
                });
            },
            removeFiles: function(index) {
                this.files.splice(index, 1);
            },
            getTotalFiles: function() {
                return this.files.length;
            }
        }
    }
</script>