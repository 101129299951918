<template>
    <step-settings-wrapper title="Page Design" v-on:close="onClose" v-on:save="onSave">
        <div class="mt-1 max-w-lg mx-auto">



            <div class="pb-5 pt-1 border-b border-gray-custom-4">

                <div class="text-3xl uppercase font-bold tracking-wider">
                    Start Screen
                </div>

                <div class="mt-5 md:relative">
                    <div class="border-b-3 border-turquoise square-box capture-overlay-square-box relative overflow-hidden md:w-1/2" ref="captureOverlayWrapper">
                        <template v-if="preset.appDesignSettings && preset.appDesignSettings.captureOverlayIsVideo">
                            <video controls class="absolute object-cover w-full h-full">
                                <source :src="api_url+'/media/'+preset.appDesignSettings.captureOverlay">
                            </video>
                        </template>
                        <img class="absolute" :src="api_url+'/media/'+preset.appDesignSettings.captureOverlay" alt="" v-else-if="preset.appDesignSettings && preset.appDesignSettings.captureOverlay" />
                        <img class="absolute object-cover w-full h-full" src="../assets/images/image_overlay_background.png" alt="" v-else />
                    </div>

                    <div class="md:w-1/2 md:pl-8 md:pr-4 md:absolute md:right-0 md:translate-100">
                        <div class="text-3xl uppercase font-bold tracking-wider mt-3 mb-2 md:mt-0 md:mb-4">
                            Overlay Image/Video
                        </div>
                        <!--<button type="button" class="pb-1 pt-3 pb-r bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-none outline-none focus:bg-turquoise w-full">
                            Upload
                        </button>-->
                        <button type="button" class="pb-1 pt-3 pb-r bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight outline-none w-full mb-5" v-if="(preset.appDesignSettings && preset.appDesignSettings.captureOverlay && removeCaptureOverlay !== true) || newCaptureOverlaySet" @click="removeCaptureOverlayImage">
                            Remove
                        </button>
                        <file-select-upload allowedTypes="image/*,video/mp4" @input="previewOverlayImage" ref="fileUpload"></file-select-upload>
                    </div>

                </div>
            </div>


            <div class="pb-5 pt-4 border-b border-gray-custom-4">

                <div class="flex flex-wrap -mx-5">

                    <div class="w-full md:w-1/2 px-5 text-3xl uppercase font-bold tracking-wider mt-2 mb-2">
                        User Start Options
                    </div>

                    <div class="w-full md:w-1/2 px-5 mt-3 mb-2">
                        <div class="float-left text-2xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Touchless
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="false" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="touchlessStartEnabled" :value="preset.appDesignSettings.touchlessStartEnabled" v-model="preset.appDesignSettings.touchlessStartEnabled"></toggle-button>
                        </div>
                    </div>

                    <div class="w-full px-5 mb-2 pb-6 border-b border-gray-custom-3 clearfix">
                        <div class="float-left text-2xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Web Share Type
                        </div>
                        <div class="float-right pt-1">

                            <div class="text-black uppercase font-bold tracking-wider text-xl">
                                <input type="radio" name="share_type" id="share_type_download" value="download" v-model="preset.appDesignSettings.shareType" class="absolute opacity-0"><label for="share_type_download" class="rounded-l" :class="[shareType.defaultClasses, preset.appDesignSettings.shareType === 'download' ? shareType.activeClasses : '']">Download To Device</label>
                                <input type="radio" name="share_type" id="share_type_email" value="email" v-model="preset.appDesignSettings.shareType" class="absolute opacity-0"><label for="share_type_email"  class="rounded-r" :class="[shareType.defaultClasses, !preset.appDesignSettings.shareType || preset.appDesignSettings.shareType === 'email' ? shareType.activeClasses : '']">Share To Email</label>
                            </div>

                        </div>

                        <p class="clear-both text-base font-work-sans-medium pt-4 mb-3 leading-tight">
                            The web share page is used when a user scans the QR code in either touch or touchless mode. You have the option to either enable ‘Share to email’ or ‘Download to device’. If ‘Share to email’ is enabled (default), this means the user will submit their email address in order to receive the media via their personal inbox.
                        </p>
                        <p class="text-base font-work-sans-medium leading-tight">
                            If ‘Download to device’ is enabled, no data is shared and the user downloads the media directly to their device via the web share page.
                        </p>
                    </div>


                    <div class="w-full md:w-1/2 px-5">
                        <div class="float-left text-2xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Photo Enabled
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="false" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="photoEnabled" :value="preset.appDesignSettings.photoEnabled" v-model="preset.appDesignSettings.photoEnabled"></toggle-button>
                        </div>
                    </div>

                    <div class="w-full md:w-1/2 px-5">
                        <div class="float-left text-2xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Boomerang Enabled
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="false" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="boomerangEnabled" :value="preset.appDesignSettings.boomerangEnabled" v-model="preset.appDesignSettings.boomerangEnabled"></toggle-button>
                        </div>
                    </div>

                </div>

                <div class="flex flex-wrap -mx-5 md:mt-2">

                    <div class="w-full md:w-1/2 px-5">
                        <div class="float-left text-2xl uppercase font-bold tracking-wider custom-line-height-42px">
                            GIF Enabled
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="false" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="gifEnabled" :value="preset.appDesignSettings.gifEnabled" v-model="preset.appDesignSettings.gifEnabled"></toggle-button>
                        </div>
                    </div>

                    <div class="w-full md:w-1/2 px-5">
                        <div class="float-left text-2xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Video Enabled
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="false" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="videoEnabled" :value="preset.appDesignSettings.videoEnabled" v-model="preset.appDesignSettings.videoEnabled"></toggle-button>
                        </div>
                    </div>

                </div>

            </div>

            <div class="pb-20 pt-4">

                <div class="text-3xl uppercase font-bold tracking-wider pt-2">
                    Colours
                </div>

                <div class="mt-5 md:relative">
                    <div class="border-b-3 border-turquoise square-box square-box-2 relative overflow-y-hidden md:w-1/2 pb-12 pt-6 px-8 bg-white" :class="{ 'capture-overlay-square-box' : (preset.appDesignSettings && preset.appDesignSettings.backgroundImage && removeBackgroundImage !== true) || newBackgroundImageSet }" :style="[{backgroundColor: preset.appDesignSettings.backgroundColorHexValue}, preset.appDesignSettings && preset.appDesignSettings.backgroundImage ? {backgroundImage: 'url('+api_url+'/media/'+preset.appDesignSettings.backgroundImage+')'} : '']" ref="backgroundImageWrapper">

                        <span class="absolute left-50 -translate-x-50 text-black bottom-0 text-3xl uppercase font-bold tracking-wider leading-snug" :style="{color: preset.appDesignSettings.textColorHexValue}">HEY!</span>
                    </div>

                    <div class="mt-5 sm:mt-0 md:w-1/2 md:pl-8 md:absolute md:right-0 md:top-0">

                        <div class="flex">
                            <div class="w-1/2 border-b-3 border-turquoise square-box mr-3 cursor-pointer relative" :style="{background: preset.appDesignSettings.backgroundColorHexValue}" @click="showModal = displayBGPicker = true"></div>
                            <div class="w-1/2 border-b-3 border-turquoise square-box ml-3 cursor-pointer relative" :style="{background: preset.appDesignSettings.textColorHexValue}" @click="showModal = displayTextPicker = true"></div>
                        </div>

                        <div class="flex pt-1 leading-relaxed">
                            <div class="w-1/2 mr-3 uppercase text-xl font-bold tracking-wider text-center">Background</div>
                            <div class="w-1/2 ml-3 uppercase text-xl font-bold tracking-wider text-center">Text</div>
                        </div>

                        <div class="w-full mt-4">
                        <div class="text-3xl uppercase font-bold tracking-wider mt-3 mb-2 md:mt-0 md:mb-2">
                            Background Image
                        </div>
                        <!--<button type="button" class="pb-1 pt-3 pb-r bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-none outline-none focus:bg-turquoise w-full">
                            Upload
                        </button>-->
                        <button type="button" class="pb-1 pt-3 pb-r bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight outline-none w-full mb-5" v-if="(preset.appDesignSettings && preset.appDesignSettings.backgroundImage && removeBackgroundImage !== true) || newBackgroundImageSet" @click="removeBackgroundImageSelected">
                            Remove
                        </button>
                        <file-select-upload allowedTypes="image/*" @input="previewBackgroundImage" ref="fileUploadBackgroundImage"></file-select-upload>
                    </div>

                    </div>

                </div>
            </div>

        </div>
        <modal :showing="showModal" transition="scale" @close="showModal = displayBGPicker = displayTextPicker = false">
            <color-picker :color="preset.appDesignSettings.backgroundColorHexValue" v-if="displayBGPicker" v-on:save="setBackgroundColorHex"></color-picker>
            <color-picker :color="preset.appDesignSettings.textColorHexValue" v-if="displayTextPicker" v-on:save="setTextColorHex"></color-picker>
        </modal>
    </step-settings-wrapper>
</template>

<script>
    // @ is an alias to /src
    import StepSettingsWrapper from '@/components/StepSettingsWrapper.vue'
    import Modal from '@/components/Modal.vue'
    import ColorPicker from "@/components/ColorPicker"
    import FileSelectUpload from "@/components/FileSelectUpload.vue";
    import placeHolderImage from '../assets/images/image_overlay_background.png';
    import { ToggleButton } from 'vue-js-toggle-button';

    export default {
        name: 'AppDesignSettings',
        data: function() {
            return {
                showModal: false,
                displayBGPicker: false,
                displayTextPicker: false,
                beforeEditing: {},
                api_url: process.env.VUE_APP_BOOTHCLUBAPP_API_ENDPOINT,
                removeCaptureOverlay: false,
                newCaptureOverlaySet: false,
                removeBackgroundImage: false,
                newBackgroundImageSet: false,
                shareType: {
                    defaultClasses: 'inline-block px-4 pt-1 border-r border-gray-custom cursor-pointer bg-white',
                    activeClasses: 'bg-turquoise'
                },
            }
        },
        props: {
            preset: Object,
            toggleButtonSettings: Object
        },
        components: {
            StepSettingsWrapper,
            Modal,
            ColorPicker,
            ToggleButton,
            FileSelectUpload
        },
        mounted: function() {
            this.beforeEditing = Object.assign({}, this.preset.appDesignSettings);
        },
        methods: {
            setBackgroundColorHex: function(hex) {
                this.preset.appDesignSettings.backgroundColorHexValue = hex;
                this.showModal = this.displayBGPicker = this.displayTextPicker = false;
            },
            setTextColorHex: function(hex) {
                this.preset.appDesignSettings.textColorHexValue = hex;
                this.showModal = this.displayBGPicker = this.displayTextPicker = false;
            },
            onClose: function(toRoute) {
                //this.preset.appDesignSettings = Object.assign({}, this.beforeEditing);
                let filesSelected = this.$refs.fileUpload.getTotalFiles() + this.$refs.fileUploadBackgroundImage.getTotalFiles();
                this.$emit('close', 'appDesignSettings', this.beforeEditing, filesSelected, toRoute);
            },
            onSave: async function() {
                const result = await this.$refs.fileUpload.saveFiles();
                if( result !== false ) {
                    this.preset.appDesignSettings.removeCaptureOverlay = this.removeCaptureOverlay;
                    this.preset.appDesignSettings.captureOverlayS3Key = result;
                    //this.$emit('save', 'appDesignSettings');
                }
                const resultBackgroundImage = await this.$refs.fileUploadBackgroundImage.saveFiles();
                if( resultBackgroundImage !== false ) {
                    this.preset.appDesignSettings.removeBackgroundImage = this.removeBackgroundImage;
                    this.preset.appDesignSettings.backgroundImageS3Key = resultBackgroundImage;
                }
                if( result !== false || resultBackgroundImage !== false ) {
                    this.$emit('save', 'appDesignSettings');
                }
            },
            previewOverlayImage: function(files) {
                if( files.length === 0 ) return;
                let img, video;
                this.removeCaptureOverlayPreviewElements();
                if( files[0].type.startsWith('video/') ) {
                    video = document.createElement('video');
                    video.src = URL.createObjectURL(files[0]);
                    video.className = 'absolute object-cover w-full h-full';
                    video.controls = 'controls';
                    this.$refs.captureOverlayWrapper.appendChild(video);
                } else {
                    img = document.createElement('img');
                    img.src = URL.createObjectURL(files[0]);
                    img.className = 'absolute';
                    this.$refs.captureOverlayWrapper.appendChild(img);
                }
                this.newCaptureOverlaySet = true;
            },
            removeCaptureOverlayImage: function() {
                let img;
                this.removeCaptureOverlayPreviewElements();
                img = document.createElement('img');
                img.src = placeHolderImage;
                img.className = 'absolute object-cover w-full h-full';
                this.$refs.captureOverlayWrapper.appendChild(img);
                this.removeCaptureOverlay = true;
                this.newCaptureOverlaySet = false;
                this.$refs.fileUpload.removeFiles(0);
            },
            removeCaptureOverlayPreviewElements: function() {
                if( this.$refs.captureOverlayWrapper.getElementsByTagName('video').length > 0 ) {
                    this.$refs.captureOverlayWrapper.getElementsByTagName('video')[0].parentNode.removeChild(this.$refs.captureOverlayWrapper.getElementsByTagName('video')[0]);
                } else if( this.$refs.captureOverlayWrapper.getElementsByTagName('img').length > 0 ) {
                    this.$refs.captureOverlayWrapper.getElementsByTagName('img')[0].parentNode.removeChild(this.$refs.captureOverlayWrapper.getElementsByTagName('img')[0]);
                }
            },
            previewBackgroundImage: function(files) {
                if( files.length === 0 ) return;
                this.$refs.backgroundImageWrapper.style.backgroundImage = "url('"+URL.createObjectURL(files[0])+"')";
                this.newBackgroundImageSet = true;
            },
            removeBackgroundImageSelected: function() {
                this.$refs.backgroundImageWrapper.style.backgroundImage = '';
                this.removeBackgroundImage = true;
                this.newBackgroundImageSet = false;
                this.$refs.fileUploadBackgroundImage.removeFiles(0);
            }
        }
    }
</script>

<style scoped>
    .square-box {
        /*background:url(../assets/images/Gold-foil-room.jpg) no-repeat;*/
        /*background-size: cover;*/
    }
    .square-box:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    .capture-overlay-square-box:after {
        padding-bottom: 133.21%;
    }
    .square-box-2 {
        background: #FFFFFF;
    }
    .square-box-2:after {
        background:url(../assets/images/image_overlay_background.png) no-repeat #FFFFFF;
        background-size: contain;
        background-color: transparent;
    }
    @media (min-width: 768px) {
        .md\:translate-100 {
            transform: translateY(-100%);
        }
        .md\:top-100 {
            top: 100%;
        }
    }
</style>