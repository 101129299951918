<template>
    <div class="color-picker">
        <div class="w-20 h-20 mx-auto bg-white border-2 border-white" :style="displayColor"></div>
        <div class="sliders my-8">
            <div class="flex my-5">
                <div class="w-12 mr-1"><label for="red" class="text-xl uppercase font-bold tracking-wider">Red</label></div>
                <div class="flex-1"><input v-model="rgb.r" type="range" min="0" max="255" id="red" class="w-full" ref="red"></div>
                <div class="w-12 ml-1"><input type="number" id="redNum" disabled class="appearance-none bg-black text-xl uppercase font-bold tracking-wider max-w-full text-right" v-model="rgb.r"></div>
            </div>
            <div class="flex my-5">
                <div class="w-12 mr-1"><label for="green" class="text-xl uppercase font-bold tracking-wider">Green</label></div>
                <div class="flex-1"><input v-model="rgb.g" type="range" min="0" max="255" id="green" class="w-full" ref="green"></div>
                <div class="w-12 ml-1"><input type="number" id="greenNum" disabled class="appearance-none bg-black text-xl uppercase font-bold tracking-wider max-w-full text-right" v-model="rgb.g"></div>
            </div>
            <div class="flex my-5">
                <div class="w-12 mr-1"><label for="blue" class="text-xl uppercase font-bold tracking-wider">Blue</label></div>
                <div class="flex-1"><input v-model="rgb.b" type="range" min="0" max="255" id="blue" class="w-full" ref="blue"></div>
                <div class="w-12 ml-1"><input type="number" id="blueNum" disabled class="appearance-none bg-black text-xl uppercase font-bold tracking-wider max-w-full text-right" v-model="rgb.b"></div>
            </div>
        </div>
        <div class="relative">
            <span class="absolute bg-gray-custom hex-code-circle"></span><input type="text" class="bg-transparent w-full pl-6 pb-3 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-sm text-white" placeholder="Hex Code" v-model="hex" @input="setColor($event)" maxlength="7" />
        </div>
        <div class="mt-10">
            <button type="button" class="bg-white text-black font-dense tracking-wider text-3xl font-bold w-full border-b-3 border-turquoise leading-tight pt-2 pb-1 outline-none uppercase focus:bg-turquoise" @click="save">Save</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ColorPicker',
        props: {
            color: String
        },
        data: function() {
            return {
                rgb: {
                    r: 255,
                    g: 255,
                    b: 255
                },
                hex: '#FFFFFF'
            }
        },
        mounted: function() {
            if( this.isValidHex(this.color) ) {
                const rgb = this.hexToRgb(this.color);
                if( rgb !== null ) {
                    this.rgb = rgb;
                    this.hex = this.color;
                }
            }
        },
        methods: {
            hexToRgb: function(hex) {
                // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                    return r + r + g + g + b + b;
                });

                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                } : null;
            },
            componentToHex: function(c) {
                const hex = Number(c).toString(16);
                return hex.length === 1 ? "0" + hex : hex;
            },
            rgbToHex: function(r, g, b) {
                return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
            },
            sliderFill: function() {
                let val, percent;

                val = this.rgb.r / 255;
                percent = val * 100;
                this.$refs.red.style.background = `linear-gradient(to right, rgb(${this.rgb.r},0,0) ${percent}%, #FFFFFF 0%)`;

                val = this.rgb.g / 255;
                percent = val * 100;
                this.$refs.green.style.background = `linear-gradient(to right, rgb(0,${this.rgb.g},0) ${percent}%, #FFFFFF 0%)`;

                val = this.rgb.b / 255;
                percent = val * 100;
                this.$refs.blue.style.background = `linear-gradient(to right, rgb(0,0,${this.rgb.b}) ${percent}%, #FFFFFF 0%)`;

            },
            isValidHex: function(hex_value) {
                return /^#[0-9A-F]{6}$/i.test(hex_value);
            },
            setColor: function(event) {
                if( this.isValidHex(event.target.value) ) {
                    const rgb = this.hexToRgb(event.target.value);
                    if( rgb !== null ) {
                        this.rgb = rgb;
                    }
                }
            },
            save: function() {
                if( this.isValidHex(this.hex) ) {
                    this.$emit('save', this.hex);
                } else {
                    alert('Please enter valid color hex');
                }
            }
        },
        computed: {
            displayColor: function() {
                return {
                    backgroundColor: `rgb(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b})`
                }
            }
        },
        watch: {
            rgb: {
                handler: function() {
                    this.sliderFill();
                    this.hex = this.rgbToHex(this.rgb.r, this.rgb.g, this.rgb.b).toUpperCase();
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    /* range sliders */
    .sliders input[type=range]{
        height: 4px;
        border-radius: 8px;
        outline: none;
        width: 100%;
        position: relative;
        top: -2px;
    }

    .sliders input[type=range], input[type=range]::-webkit-slider-thumb{
        appearance: none;
        -webkit-appearance: none;
    }

    /* slider thumb */
    .sliders input[type=range]::-webkit-slider-thumb{
        height: 18px;
        width: 18px;
        border-radius: 100%;
        cursor: pointer;
        background: #FFFFFF;
        box-shadow: 0 1px 0 0 #0DE9FF;
    }
    .hex-code-circle {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        top: 5px;
    }
</style>